import { HOME_ABOUT, MISSION, MISSION_TEXT, VISION, VISION_TEXT, WELCOME_HEADING } from "./CommonTexts"


const HomeAbout = () => {

    return (
        <div className="About">
            <div className="HomeImage"><img src= {require("../Images/2.jpg")} alt="Academy Logo"/></div>
            <h3>{WELCOME_HEADING}</h3>
            <p>{HOME_ABOUT}</p>
            <h3>{VISION}</h3>
            <p>{VISION_TEXT}</p>
            <h3>{MISSION}</h3>
            <p>{MISSION_TEXT}</p>
            <div className="dummyPad"></div>
        </div>
    )
}
export default HomeAbout