import { 
    CATEGORY_1_SYL, EXAM_DURATION, EXAM_DURATION_1, EXAM_PATTERN_1, EXAM_PATTERN_HEAD, 
    NEGATIVE_MARKING_1, NEGATIVE_MARKING_HEAD, SYLLABUS, TABLE_MARKS, TABLE_MARKS_1, TABLE_MARKS_2, TABLE_MARKS_3, 
    TABLE_SL_NO, TABLE_SUB, TABLE_SUB_1, TABLE_SUB_2, TABLE_SUB_3, TOTAL_MARKS_1, 
    TOTAL_MARKS_HEAD, TOTAL_QUES_1, TOTAL_QUES_HEAD 
} from './CommonTexts'

const Syllabus = () => {
    return (
        <div className="About">
            <h2>{SYLLABUS}</h2>
            <h3>{CATEGORY_1_SYL}</h3>
            <p><strong>{TOTAL_QUES_HEAD}</strong>{TOTAL_QUES_1}</p>
            <p><strong>{TOTAL_MARKS_HEAD}</strong>{TOTAL_MARKS_1}</p>
            {/* <p><strong>{NEGATIVE_MARKING_HEAD}</strong>{NEGATIVE_MARKING_1}</p> */}
            <p><strong>{EXAM_PATTERN_HEAD}</strong>{EXAM_PATTERN_1}</p>
            <p><strong>{EXAM_DURATION}</strong>{EXAM_DURATION_1}</p>
            <div>
                <table className='TableStyle'>
                    <tr>
                        <th>{TABLE_SL_NO}</th>
                        <th>{TABLE_SUB}</th>
                        <th>{TABLE_MARKS}</th>
                    </tr>
                    <tr>
                        <td>{1}</td>
                        <td>{TABLE_SUB_1}</td>
                        <td>{TABLE_MARKS_1}</td>
                    </tr>
                    <tr>
                        <td>{2}</td>
                        <td>{TABLE_SUB_2}</td>
                        <td>{TABLE_MARKS_2}</td>
                    </tr>
                    <tr>
                        <td>{3}</td>
                        <td>{TABLE_SUB_3}</td>
                        <td>{TABLE_MARKS_3}</td>
                    </tr>
                </table>
            </div>
            <div className='dummyPad'></div>
            
        </div>
    )
}

export default Syllabus