import styles from './App.css';
import Header from './components/Header';
import HomeAbout from './components/HomeAbout';
import Footer from './components/Footer';
import Team from './components/Team';
import Gallery from './components/Gallery';
import Contact from './components/Contact';
import Activities from './components/Activities';
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import Objectives from './components/Objectives';
import Syllabus from './components/Syllabus';
import AboutUs from './components/AboutUs';
import WhyAA from './components/WhyAA';


const headerTabs = () => ([
  {label: 'Home', content: <HomeAbout/>},
  {label: 'About Us', content: <AboutUs/>},
  {label: 'Why Us', content: <WhyAA/>},
  {label: 'Objectives', content: <Objectives/>},
  {label: 'Activties', content: <Activities/>},
  // {label: 'Gallery', content: <Gallery/>},
  // {label: 'Team', content: <Team/>},
  {label: 'Syllabus', content: <Syllabus/>},
  {label: 'Contact', content: <Contact/>},
])

function App() {
  const [tabSlot, setTabSlot] = useState(0)

  const handleChange = (event, newValue) => {
    setTabSlot(newValue)
  }

  const renderTabsContent = (children, value, index) => (
    <Grid xs={12}>
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
      >
        {value === index && <Box>{children}</Box>}
      </Typography>
    </Grid>
  )

  return (
    <div>
      <Header/>
      <div className='HeaderAppStyle'>
        <Box sx={{ width: '100%', bgcolor: 'background.paper', background: 'rgb(255, 196, 0)' }}>
          <Tabs
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          width = '100%'
          value={tabSlot}
          className='BoldStyle'
          onChange={handleChange}
          >
            {headerTabs().map((tab, index) => (
              <Tab key={index} label={tab.label} />
            ))}
          </Tabs>
        </Box>
        </div>
        {headerTabs().map((tab, index) => (
          renderTabsContent(tab.content, tabSlot, index)
          ))}
        <Footer/>
    </div>
  )
}

export default App;
