import { Grid } from "@mui/material"
import { ACADEMY_NAME } from "./CommonTexts"


const Header = () => {
    return (
        <div className="HeaderDiv">
            <Grid container>
            <Grid item xs={3} alignContent="left">
                <img src= {require("../Images/LogoNoBg.png")} alt="Academy Logo" width={'220px'} height={'75px'}/>
            </Grid>
            <Grid item xs={6}>
                <div className="HeaderText">
                    <strong>{ACADEMY_NAME}</strong>
                </div>
            </Grid>
        </Grid>
        </div>
        
    )

}

export default Header