import { Comprehensive_Testing, Comprehensive_Testing_txt, END_LINE, EXPERTISE, EXPERTISE_TEXT, HOME_ABOUT, Pathway_Success, Pathway_Success_txt, Personalized_Approach, Personalized_Approach_txt, Prizes_Recognition, Prizes_Recognition_txt, WELCOME_HEADING, WHY_CHOOSE } from "./CommonTexts"


const WhyAA = () => {

    return (
        <div className="About">
            <h3>{WHY_CHOOSE}</h3>
            <ul>
                <li><p><strong>{EXPERTISE}</strong>{EXPERTISE_TEXT}</p></li>
                <li><p><strong>{Comprehensive_Testing}</strong>{Comprehensive_Testing_txt}</p></li>
                <li><p><strong>{Personalized_Approach}</strong>{Personalized_Approach_txt}</p></li>
                <li><p><strong>{Prizes_Recognition}</strong>{Prizes_Recognition_txt}</p></li>
                <li><p><strong>{Pathway_Success}</strong>{Pathway_Success_txt}</p></li>
            </ul>
            <p>{END_LINE}</p>
        </div>
    )
}

export default WhyAA