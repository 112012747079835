import { ACTIVITIES, ACTIVITIES_SUB_HEAD, ACTIVITY_ONE, ACTIVITY_THREE, ACTIVITY_TWO, CATEGORY_1, CATEGORY_2, CATEGORY_3, SUB_HEAD_1, SUB_HEAD_2 } from "./CommonTexts"


const Activities = () => {
    return (
        <div className="About">
            <h3>{ACTIVITIES}</h3>
            <h4>{ACTIVITIES_SUB_HEAD}</h4>
            <ul>
                <li><strong>{CATEGORY_1}</strong></li>
                <ul>
                    <li>{SUB_HEAD_1}</li>
                    <li>{ACTIVITY_ONE}</li>
                </ul>
                <li><strong>{CATEGORY_2}</strong></li>
                <ul>
                    <li>{SUB_HEAD_2}</li>
                    <li>{ACTIVITY_TWO}</li>
                </ul>
                <li><strong>{CATEGORY_3}</strong></li>
                <ul>
                    <li>{SUB_HEAD_2}</li>
                    <li>{ACTIVITY_THREE}</li>
                </ul>
                
            </ul>
        </div>
    )
}

export default Activities