import { ACADEMY_NAME_NORMAL, AREA, CITY, CONTACT_HEAD, COUNTRY, EMAIL, MOBILE, PHONE, PIN, STREET } from "./CommonTexts"

const Contact = () => {
    return (
        <div className="ContactUs">
            <h2><strong>{CONTACT_HEAD}</strong></h2>
            <p>{ACADEMY_NAME_NORMAL}</p>
            <p>{STREET}</p>
            <p>{AREA}</p>
            <p>{CITY}{" - "}{PIN}</p>
            <p>{COUNTRY}</p>
            {/* <p>{PHONE}</p> */}
            <p>{MOBILE}</p>
            <p>{EMAIL}</p>
        </div>
    )
}

export default Contact