// HEADER Constants
export const ACADEMY_NAME = "ARYABHATTA ACADEMY"
export const ACADEMY_NAME_NORMAL = "Aryabhatta Academy"


//Home Page
export const WELCOME_HEADING = "Welcome to Aryabhatta Academy"
export const HOME_ABOUT = "At Aryabhatta Academy, we believe that every student has a unique set of talents waiting to be discovered and nurtured. Our mission is to provide a platform that not only identifies these talents but also helps students develop them to their full potential. We are dedicated to fostering excellence and empowering young minds to shine brightly in their academic journey and beyond."
export const VISION_TEXT = "Empowering the Future through Discovery and Excellence: Our vision is to create a world where every individual's distinct talents are recognized, nurtured, and celebrated. We envision an academy that stands as a beacon of opportunity, fostering a culture of exploration, innovation, and personal growth through meticulously crafted talent tests. Through our unwavering commitment to identifying and developing potential, we aspire to inspire a generation of leaders who will shape a brighter tomorrow."
export const VISION = "Vision: "
export const MISSION = "Mission: "
export const MISSION_TEXT = "At Aryabhatta Academy, our mission is to cultivate individuality and excellence through purposeful talent testing. We are dedicated to providing a platform that unearths and refines the diverse abilities of students, fostering an environment where talents are celebrated regardless of their form. Through rigorous and fair assessments, personalized guidance, and a commitment to holistic development, we aim to empower students to embrace their unique strengths, ignite their passions, and embark on a lifelong journey of self-discovery and impactful contributions."
export const ABOUT_US = "About us"
export const ABOUT_US_CONTENT = "Aryabhatta Academy is a premier institution dedicated to conducting talent tests for students across various disciplines. Named after the legendary Indian mathematician and astronomer Aryabhatta, our academy embodies the spirit of exploration, curiosity, and intellectual growth. We understand that each student is a universe of untapped potential, and our goal is to uncover their strengths while providing them with the guidance they need to succeed."

// OBJECTIVES
export const OUR_OBJECTIVES = "Our Objectives"
export const OBJECTIVES_1 = "Our primary objective is to identify and celebrate the diverse talents that students possess. We achieve this by organizing comprehensive talent tests that cover a wide range of subjects, including mathematics, science, literature, arts, and more. Through these tests, we aim to:"
// export const OBJECTIVES_2 = "Through these tests, we aim to:"
export const OBJECTIVES_3_HEAD = "Encourage Exploration: "
export const OBJECTIVES_3 = "We inspire students to explore various areas of knowledge and discover their interests beyond the confines of the traditional classroom."
export const OBJECTIVES_4_HEAD = "Promote Excellence: "
export const OBJECTIVES_4 = "We recognize and reward exceptional performance, motivating students to strive for excellence in their chosen fields."
export const OBJECTIVES_5_HEAD = "Provide Guidance: "
export const OBJECTIVES_5 = "For students who show promise in specific subjects, we offer guidance, mentorship, and resources to help them further develop their skills."

// Footer
export const FOOTER = " Copyrights reserved by Aryabhatta Academy, Hyderabad. Designed by Pavan Chivurala"

//Contact
export const CONTACT_HEAD = "Reach us at"
export const STREET = "Flat 101"
export const AREA = "Ishwank Arcade, Srinivasa Enclave"
export const CITY = "Kushaiguda, Hyderabad"
export const PIN = "500062"
export const COUNTRY = "India"
export const PHONE = "040-12345678"
export const MOBILE = "+91 9347382042"
export const EMAIL = "aryabhattaacademy48@gmail.com"

//Activities List
export const ACTIVITIES = "ACTIVITIES"
export const ACTIVITIES_SUB_HEAD = "Our  Academy will conduct the talent test for 3 category students:"
export const CATEGORY_1 = "Category 1: Primary Explorers (5th to 10th Grade)"
export const SUB_HEAD_1 = "Next gen Science & GK Talent Search Examination"
export const ACTIVITY_ONE = "This category is designed for students from 5th to 10th grade, focusing on fundamental skills and basic concepts across all subjects and General knowledge."
export const CATEGORY_2 = "Category 2: Intermediate Achievers (11th and 12th Grade)"
export const SUB_HEAD_2 = "Next gen Talent search examination for Intermediate."
export const ACTIVITY_TWO = "Targeted at students in the intermediate grades, this category should reflect a slightly higher level of difficulty and complexity. Focusing on all concepts of respective branch subjects."
export const CATEGORY_3 = "Category 3:  Degree Achievers (11th and 12th Grade)"
export const SUB_HEAD_3 = "Next gen Talent search examination for degree students."
export const ACTIVITY_THREE = "This category is intended for college and university students, encompassing a broader spectrum of subjects and more advanced concepts"

// WHY CHOOSE

export const WHY_CHOOSE = "Why Choose Aryabhatta Academy?"
export const EXPERTISE = "Expertise : "
export const EXPERTISE_TEXT = "Our team consists of experienced educators and professionals who are passionate about nurturing young talent."
export const Comprehensive_Testing = "Comprehensive Testing : "
export const Comprehensive_Testing_txt = "Our talent tests are designed to assess a wide range of skills, ensuring a holistic evaluation of each student's abilities."
export const Personalized_Approach = "Personalized Approach : "
export const Personalized_Approach_txt = "We understand that every student is unique. Our approach is tailored to individual strengths and needs."
export const Prizes_Recognition = "Prizes and Recognition : "
export const Prizes_Recognition_txt = "High achievers in our talent tests are honored with awards, certificates, and the opportunity to showcase their talents"
export const Pathway_Success = "Pathway to Success : "
export const Pathway_Success_txt = "For students aiming for competitive exams or specialized fields, we provide insights and guidance to help them pursue their dreams."
export const END_LINE = "Join us at Aryabhatta Academy and embark on a journey of self-discovery, growth, and accomplishment. Together, we will unlock the doors to a world of endless possibilities"

//Syllabus
export const SYLLABUS = "Syllabus"
export const CATEGORY_1_SYL = "Category 1: Primary Explorers (5th to 10th Grade)"
export const TOTAL_QUES_HEAD = "Total Questions: "
export const TOTAL_QUES_1 = "100 Questions"
export const TOTAL_MARKS_HEAD = "Total Marks: "
export const TOTAL_MARKS_1 = "100"
export const NEGATIVE_MARKING_HEAD = "Negative Marking: "
export const NEGATIVE_MARKING_1 = "There is negative marking for each wrong answer"
export const EXAM_PATTERN_HEAD = "Exam Pattern: "
export const EXAM_PATTERN_1 = "Multiple Choice Questions"
export const EXAM_DURATION = "Exam Duration: "
export const EXAM_DURATION_1 = "60 minutes"
export const TABLE_SL_NO = "SL. No"
export const TABLE_SUB = "NAME OF THE SUBJECT"
export const TABLE_MARKS = "MARKS"
export const TABLE_SUB_1 = "Mathematics"
export const TABLE_SUB_2 = "Science"
export const TABLE_SUB_3 = "General Knowledge"
export const TABLE_MARKS_1 = "40"
export const TABLE_MARKS_2 = "40"
export const TABLE_MARKS_3 = "20"
 


