import { ABOUT_US, ABOUT_US_CONTENT } from "./CommonTexts"

const AboutUs = () => {

    return (
        <div className="About">
            <h3>{ABOUT_US}</h3>
            <p>{ABOUT_US_CONTENT}</p>
        </div>
    )
}

export default AboutUs