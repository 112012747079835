import { FOOTER } from "./CommonTexts"
import CopyrightIcon from '@mui/icons-material/Copyright';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import "./Footer.css"
import { Link } from "@mui/material";

function Footer() {
    return (
        <div className="FooterPaper">
            {/* <div className="TextFooter">
                <Link href= "https://www.facebook.com"><FacebookIcon/></Link>
                <Link href= "https://www.instagram.com"><InstagramIcon/></Link>
                <Link href= "https://www.twitter.com"><TwitterIcon/></Link>
                <Link href= "https://www.youtube.com"><YouTubeIcon/></Link>
            </div> */}
            <p className="TextFooter">{<CopyrightIcon fontSize="3px"/>}{FOOTER}</p>
        </div>
    )
}

export default Footer