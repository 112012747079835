import { OBJECTIVES_1, OBJECTIVES_3, OBJECTIVES_3_HEAD, OBJECTIVES_4, OBJECTIVES_4_HEAD, OBJECTIVES_5, OBJECTIVES_5_HEAD, OUR_OBJECTIVES } from "./CommonTexts"


const Objectives = () => {

    return (
        <div className="About">
            <h3>{OUR_OBJECTIVES}</h3>
            <p>{OBJECTIVES_1}</p>
            <p><strong>{OBJECTIVES_3_HEAD}</strong>{OBJECTIVES_3}</p>
            <p><strong>{OBJECTIVES_4_HEAD}</strong>{OBJECTIVES_4}</p>
            <p><strong>{OBJECTIVES_5_HEAD}</strong>{OBJECTIVES_5}</p>
        </div>
    )
}
export default Objectives